import router from './router'
import store from '@/store'
import { Message } from 'element-ui'
const whiteList = ['/login'] // 白名单


// 路由前置守卫
router.beforeEach(async(to, from, next) => {
    // start progress bar
    const hasToken = store.state.access_token
    const hasGetUserInfo = store.state.userInfo
    if (hasToken) {
      if (to.path === '/login') {
        next({ path: '/' })
      } else {
        if (hasGetUserInfo) {
            next()
        } else {
            // 没有用户信息
            try {
                // get user info
                await store.dispatch('getInfo')
                next()
            } catch (error) {
                await store.dispatch('RefreshToken')
                Message.error('登录已过期，请重新登录')
                next(`/login?redirect=${to.path}`)
            }
        }
      }
    } else {
        if (whiteList.indexOf(to.path) !== -1 || to.path.indexOf('/view')!= -1) {
            next()
        } else {
            next(`/login?redirect=${to.path}`)
        }
    }
})
