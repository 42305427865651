import Vue from 'vue'
import Vuex from 'vuex'
import { getStore, setStore,removeStore } from '@/utils/store'
import { loginByUsername, refreshToken,logout,getUserInfo } from '@/api/login'
import { encryption } from '@/utils/utils'
import Cookies from 'js-cookie'
Vue.use(Vuex)


function getToken() {
  if(Cookies.get('WYSHOP-TOEKN')) {
    setStore({
      name: 'access_token',
      content: Cookies.get('WYSHOP-TOEKN'),
      type: 'session'
    })
  }
}
getToken()

const store = new Vuex.Store({
  state: {
    userInfo: null,
    expires_in: getStore({
      name: 'expires_in'
    }) || '',
    access_token: getStore({
      name: 'access_token'
    }) || '',
    refresh_token: getStore({
      name: 'refresh_token'
    }) || ''
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, access_token) => {
      state.access_token = access_token
      setStore({
        name: 'access_token',
        content: access_token,
        type: 'session'
      })
    },
    SET_EXPIRES_IN: (state, expires_in) => {
      state.expires_in = expires_in
      setStore({
        name: 'expires_in',
        content: state.expires_in,
        type: 'session'
      })
    },
    SET_REFRESH_TOKEN: (state, rfToken) => {
      state.refresh_token = rfToken
      setStore({
        name: 'refresh_token',
        content: state.refresh_token,
        type: 'session'
      })
    },
    SET_USER:(state,payload) => {
      state.userInfo = payload
  },
  },
  actions: {
    getInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(res=>{
          if (res.data.data) {
            commit('SET_USER', res.data.data.sysUser)
            resolve()
          } else {
            // 未登录 或token已过期
            reject('获取用户信息失败')
          }
        })
      })
    },
    LoginByUsername({ commit }, userInfo) {
      const user = encryption({
        data: userInfo,
        key: 'pigxpigxpigxpigx',
        // key:'lsfwlsfwlsfwlsfw',
        param: ['password']
      })
      return new Promise((resolve, reject) => {
        loginByUsername(user.username, user.password, user.code, user.randomStr).then(response => {
          const data = response.data
          commit('SET_ACCESS_TOKEN', data.access_token)
          commit('SET_REFRESH_TOKEN', data.refresh_token)
          commit('SET_EXPIRES_IN', data.expires_in)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    RefreshToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        refreshToken(state.refresh_token).then(response => {
          const data = response.data
          commit('SET_ACCESS_TOKEN', data.access_token)
          commit('SET_REFRESH_TOKEN', data.refresh_token)
          commit('SET_EXPIRES_IN', data.expires_in)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          removeStore({
            name: 'access_token',
            type: 'session'
          })
          commit('SET_ACCESS_TOKEN', '')
          commit('SET_REFRESH_TOKEN', '')
          commit('SET_EXPIRES_IN', '')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 注销session
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        removeStore({
          name: 'access_token',
          type: 'session'
        })
        commit('SET_ACCESS_TOKEN', '')
        commit('SET_REFRESH_TOKEN', '')
        resolve()
      })
    },
  }
})
export default store